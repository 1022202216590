<template>
  <CreditCardTemplate
    :formFlag="formFlag"
    :hideUpload="hideUpload"
    :resultFlag="resultFlag"
    :successFlag="successFlag"
    :icon_show_success.sync="icon_show_success"
    :icon_show_fail="icon_show_fail"
    :resultMessage.sync="resultMessage"
    :userId="userId"
    :paymentDepositId.sync="paymentDepositId"
  >
    <div class="main-deposit" v-if="formFlag">
      <div class="max">
        <el-steps direction="vertical">
          <el-step icon="red"></el-step>
          <el-step icon="blue"></el-step>
          <el-step icon="red"></el-step>
        </el-steps>
        <div class="depositFunds-box">
          <div class="form-left">
            <div class="content">
              <div class="info-box">
                <p>{{ $t('deposit.cc.header') }}</p>
                <DepositInfo :instData="instData" :noteData="noteData"></DepositInfo>
              </div>
              <div class="form_main">
                <p class="title">{{ $t('deposit.cardpay.form.header') }}</p>
                <el-form
                  label-position="top"
                  :model="virtualPayForm"
                  ref="virtualPayForm"
                  status-icon
                  :rules="virtualPayRules"
                >
                  <div class="form-box">
                    <ul class="clearfix">
                      <li>
                        <AccountNumber
                          :supportedCurrenciesList="validCurrencies"
                          @setCurrency="setCurrency"
                          @setAccountNumber="setAccountNumber"
                        ></AccountNumber>
                      </li>
                      <li>
                        <el-form-item :label="setAmtLabel(accountCurrency, virtualPayForm.amount)" prop="amount">
                          <numeric-input
                            v-model="virtualPayForm.amount"
                            :currency="accountCurrency"
                            :precision="2"
                          ></numeric-input>
                        </el-form-item>
                      </li>
                    </ul>
                    <ul class="clearfix">
                      <li>
                        <el-form-item :label="$t('common.field.imptNotes')" prop="notes">
                          <el-input v-model="virtualPayForm.notes" data-testid="imptNotes"></el-input>
                        </el-form-item>
                      </li>
                    </ul>
                  </div>
                  <el-form-item>
                    <el-button class="btn-blue" :loading="loading" @click="submitForm()" data-testid="submit">
                      {{ $t('common.button.submit') }}
                    </el-button>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
          <div class="form-right creditcard">
            <div class="logo visa visaway"></div>
          </div>
        </div>
      </div>
    </div>
  </CreditCardTemplate>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
import CreditCardTemplate from '@/components/template/deposit/CreditCardTemplate';
import DepositInfo from '@/components/payment/DepositInfo.vue';
import mixin from '@/mixins/page/deposit';
import countryCodeMixin from '@/mixins/countryCode';
import creditCard from '@/mixins/page/deposit/creditCard';
import { apiCc_payment } from '@/resource';

export default {
  name: 'virtualPayDeposit',
  components: { NumericInput, AccountNumber, CreditCardTemplate, DepositInfo },
  mixins: [mixin, countryCodeMixin, creditCard],
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (this.accountCurrency !== 'USC' && parseFloat(value) < this.minLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', {
              minLimit: Math.ceil(this.minLimit),
              currency: this.accountCurrency
            })
          )
        );
      } else if (this.accountCurrency !== 'USC' && parseFloat(value) > this.maxLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimit, currency: this.accountCurrency })
          )
        );
      } else if (this.accountCurrency === 'USC' && parseFloat(value) < this.minLimitByUSC) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', { minLimit: this.minLimitByUSC, currency: this.accountCurrency })
          )
        );
      } else if (this.accountCurrency === 'USC' && parseFloat(value) > this.maxLimitByUSC) {
        callback(
          new Error(this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimitByUSC, currency: 'USC' }))
        );
      } else {
        callback();
      }
    };

    return {
      validCurrencies: ['USD', 'GBP', 'EUR', 'CAD', 'JPY'],
      virtualPayForm: {
        accountNumber: '',
        amount: '',
        notes: ''
      },
      virtualPayRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ],
        notes: [
          {
            pattern: /^.{0,256}$/,
            message: this.$t('common.formValidation.note256'),
            trigger: 'blur'
          }
        ]
      },
      instData: [
        this.$t('deposit.reminder.inst1'),
        this.$t('deposit.reminder.inst4'),
        this.$t('deposit.reminder.inst5'),
        this.$t('deposit.reminder.inst6')
      ],
      noteData: [this.$t('deposit.creditOrDebit.virtualPay.note1'), this.$t('deposit.creditOrDebit.virtualPay.note2')]
    };
  },
  watch: {
    accountCurrency(newValue) {
      if (newValue == 'USD') this.maxLimit = 10000;
      else this.maxLimit = 99999999.99;
    }
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.virtualPayForm.accountNumber = accountNumber;
    },
    submitDeposit() {
      return apiCc_payment(
        {
          mt4Account: this.virtualPayForm.accountNumber,
          depositAmount: this.virtualPayForm.amount,
          applicationNotes: this.virtualPayForm.notes,
          paymentChannel: 9
        },
        this.token
      );
    },
    submitForm() {
      this.$refs['virtualPayForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              if (result.data.data && result.data.code == 0) {
                window.location = result.data.msg;
              } else if (result.data.code == 300) {
                this.formFlag = false;
                this.resultFlag = true;
              } else {
                this.loading = false;
                this.errorMessage(this.$t('deposit.default.failed'));
              }
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
@import '@/assets/css/pages/deposit/ccDefault.scss';
</style>
